<template>
    <div class="flex-1">
        <div class="flex flex-column flex-auto">
            <div class="py-2">
                <div class="grid">
                    <h1>Tabellenoptionen</h1>
                    <div class="surface-card shadow-2 border-round p-4 h-full">
                        
                        <TableFullOptions />
                    </div>
                </div>
            </div>    
        </div>
    </div>    
</template>
<script>

export default {
name: 'Accounts',
    data() {
        return {
            items: [
                {
                    label:'ausblenden',
                    icon:'pi pi-fw pi-eye-slash',
                }
            ]
        }
    },
    computed:{
        main() {
            return this.$route.path === '/Accounts';
        }
    }
}
</script>